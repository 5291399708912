<template>
  <base-document
    class="invoice-document"
    v-bind="$props"
    :loading="!invoice"
    :show-menu="editable"
    :can-unlock="isUnlockAllowed"
    @update:hovering="$emit('update:hovering', $event)"
    @unlock="$emit('unlock', $event)"
  >
    <div v-if="invoice">
      <div class="invoice-logo">
        <editable-document-item
          class="invoice-logo-box"
          image
          dashed
          :value="invoice.logo"
          :editable="allowEditing"
          @change="$emit('change', { logo: $event || null })"
        >
          <img
            alt="Invoice Logo"
            :src="invoice.logo ? invoice.logo.url : settings.logo"
            class="invoice-logo-image max-w-full max-h-full"
          />
        </editable-document-item>
      </div>

      <div class="invoice-header">
        <div class="invoice-header-top flex">
          <div class="flex-grow text-left invoice-contacts">
            <div v-if="isNational" class="w-128">
              <h2>
                Flytedesk National Advertising Invoice
                {{ invoice.transaction_date | month }}
              </h2>

              <div class="mt-5 italic">
                Questions about your statement? Reach out to
                <a
                  v-if="isInfluencer"
                  :href="'mailto:' + settings.influencerRepEmail"
                >
                  {{ settings.influencerRepEmail }}
                </a>
                <a v-else-if="repEmail" :href="'mailto:' + repEmail">
                  {{ repEmail }}
                </a>
              </div>
            </div>
            <div v-else class="invoice-customer-billing-info">
              <h3 v-if="buyer" class="invoice-customer-company">
                {{ buyer.buyer_company }} ({{ buyer.id }})
              </h3>
              <div v-if="buyer" class="invoice-customer-billing-address">
                <address-format
                  v-if="buyer.billingAddress && buyer.billingAddress.address"
                  :address="buyer.billingAddress"
                />
                <address-format
                  v-else-if="
                    buyer.primaryAddress && buyer.primaryAddress.address
                  "
                  :address="buyer.primaryAddress"
                />
                <template v-else>
                  No Address Available
                </template>
              </div>
              <div class="invoice-primary-contact">
                <h3>Contact:</h3>
                <contact-info :contact="settings.customerPrimaryContact" />
              </div>
              <div class="invoice-billing-contact">
                <h3>Billing:</h3>
                <contact-info :contact="settings.customerBillingContact" />
              </div>
            </div>
          </div>

          <div class="flex-grow text-right invoice-info">
            <div class="invoice-info-main">
              <h3>Invoice</h3>
              <div class="invoice-ref invoice-label-value">
                <div class="invoice-label">Invoice #:</div>
                <div class="invoice-value">{{ invoice.ref }}</div>
              </div>
              <div class="invoice-transaction-date invoice-label-value">
                <div class="invoice-label">Date Issued:</div>
                <div class="invoice-value">
                  <editable-document-item
                    datepicker
                    :value="invoice.transaction_date"
                    :editable="allowEditing"
                    @change="$emit('change', { transaction_date: $event })"
                  />
                </div>
              </div>
              <div class="invoice-label-value">
                <div class="invoice-label">Billing Period:</div>
                <div class="invoice-value">
                  {{ invoice.start_date | date }} -
                  {{ invoice.end_date | date }}
                </div>
              </div>
              <div
                v-if="!isInfluencer"
                class="invoice-terms invoice-label-value"
              >
                <div class="invoice-label">Terms:</div>
                <div class="invoice-value">
                  <invoice-terms-query @data="invoiceTermOptions = $event" />

                  <editable-document-item
                    select
                    :value="invoice.terms"
                    :editable="allowEditing"
                    @change="onTermsChange"
                  >
                    <template slot="select-options">
                      <el-option
                        v-for="term in invoiceTermOptions"
                        :key="`invoice-terms-${term.id}`"
                        :label="term.text"
                        :value="term"
                      />
                    </template>

                    {{ invoice.terms.text }}
                  </editable-document-item>
                </div>

                <confirm-dialog
                  v-if="showTermsChangeDialog"
                  title="Update Due Date"
                  width="30em"
                  @confirm="
                    (showTermsChangeDialog = false) ||
                      $emit('change', { due_date: termsDueDate })
                  "
                  @close="showTermsChangeDialog = false"
                >
                  <div>Would you like to update the Due Date?</div>

                  <div class="mt-5 text-center max-w-sm">
                    <el-date-picker
                      v-model="termsDueDate"
                      format="MM/dd/yyyy"
                    />
                  </div>
                </confirm-dialog>
              </div>
              <div
                v-if="!isNational"
                class="invoice-due-date invoice-label-value"
              >
                <div class="invoice-label">Due Date:</div>
                <div class="invoice-value">
                  <editable-document-item
                    datepicker
                    :value="invoice.due_date"
                    :editable="allowEditing"
                    @change="$emit('change', { due_date: $event })"
                  />
                </div>
              </div>
              <div class="invoice-total-due invoice-label-value">
                <div class="invoice-label">Total:</div>
                <div class="invoice-value">
                  {{ invoice.amount | currency }}
                </div>
              </div>
              <div
                v-if="!isNational"
                class="invoice-amount-due invoice-label-value"
              >
                <div class="invoice-label">Amount Due:</div>
                <div class="invoice-value">
                  {{ invoice.remaining_amount | currency }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="settings && !isVersion2National"
          class="invoice-header-bottom"
        >
          <table class="invoice-vendor-contact doc-table">
            <thead>
              <tr>
                <td class="vendor-organization">
                  {{ settings.organizationName }}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <address-format
                    v-if="settings.address"
                    :address="settings.address"
                  />

                  <div
                    v-if="settings.vendorBillingContact"
                    class="vendor-billing-contact"
                  >
                    Attn: {{ settings.vendorBillingContact.name }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="invoice-body">
        <div>
          <table class="invoice-line-items-table doc-table">
            <thead>
              <tr>
                <td class="column-description line-item-description">
                  Description
                </td>
                <td class="column-date line-item-date">Date</td>
                <td class="column-quantity line-item-quantity">Quantity</td>
                <td class="column-amount line-item-amount">Amount</td>
              </tr>
            </thead>
            <tbody>
              <template v-if="isVersion2National">
                <template v-for="orderGroup in groupedLineItems">
                  <tr :key="orderGroup.title">
                    <td colspan="4" class="bg-light-gray">
                      {{ orderGroup.title }}
                    </td>
                  </tr>
                  <invoice-document-line-item
                    v-for="lineItem in orderGroup.lineItems"
                    :key="lineItem.id"
                    :line-item="lineItem"
                    :locked="locked"
                    :editable="
                      allowEditing &&
                        (!!isLineItemHovered[lineItem.id] ||
                          isLineItemFocused[lineItem.id])
                    "
                    @hovering="setLineItemHovered(lineItem, $event)"
                    @focus="setLineItemFocused(lineItem, true)"
                    @blur="setLineItemFocused(lineItem, false)"
                    @change="
                      $emit('update-item', { id: lineItem.id, input: $event })
                    "
                    @remove="$emit('remove-item', lineItem.id)"
                  />
                </template>
              </template>

              <template v-else>
                <invoice-document-line-item
                  v-for="lineItem in sortedLineItems"
                  :key="lineItem.id"
                  :line-item="lineItem"
                  :locked="locked"
                  :editable="
                    allowEditing &&
                      (!!isLineItemHovered[lineItem.id] ||
                        isLineItemFocused[lineItem.id])
                  "
                  @hovering="setLineItemHovered(lineItem, $event)"
                  @focus="setLineItemFocused(lineItem, true)"
                  @blur="setLineItemFocused(lineItem, false)"
                  @change="
                    $emit('update-item', { id: lineItem.id, input: $event })
                  "
                  @remove="$emit('remove-item', lineItem.id)"
                />
              </template>

              <tr
                :key="'add-line-item'"
                class="invoice-add-line-item"
                :class="{ 'is-disabled': !allowEditing }"
                @click="
                  allowEditing &&
                    $emit('create-item', {
                      id: uuid(),
                      type: DocumentLineItemTypeKeys.FEE,
                      name: '',
                      amount: 0,
                      quantity: 1,
                      date: datetimeDb()
                    })
                "
              >
                <td colspan="4" class="text-center">
                  <icon :icon="plusIcon" />
                  Add Line Item
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <transition-group
          tag="div"
          name="fade"
          class="relative animate-position"
        >
          <div :key="'invoice-footer'" class="invoice-total-due-footer">
            <div>
              <div v-if="showTotals" class="mb-3">
                <div class="flex text-base">
                  <div class="invoice-total-label flex-grow">Sub Total:</div>
                  <div class="invoice-total-value">
                    {{ subTotal | currency }}
                  </div>
                </div>

                <div v-if="totalDiscounts" class="flex text-green text-base">
                  <div class="invoice-total-label flex-grow">Discounts:</div>
                  <div class="invoice-total-value">
                    {{ totalDiscounts | currency }}
                  </div>
                </div>

                <div v-if="totalFees" class="flex">
                  <div class="invoice-total-label">Fees:</div>
                  <div class="invoice-total-value">
                    {{ totalFees | currency }}
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="invoice-total-label flex-grow">Total:</div>
                <div class="invoice-total-value">
                  {{ invoice.amount | currency }}
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="allowEditing || invoice.notes"
            :key="'invoice-notes'"
            class="invoice-notes"
          >
            <h3 class="invoice-notes-header">Notes</h3>
            <div class="invoice-notes-body">
              <editable-document-item
                dashed
                :textarea="{
                  placeholder: 'Enter notes for your customer...'
                }"
                :value="invoice.notes"
                :editable="allowEditing"
                @change="debounceChange({ notes: $event })"
                @focus="$emit('focus')"
                @blur="$emit('blur')"
              />
            </div>
          </div>
        </transition-group>
      </div>

      <div v-if="isNational" class="mt-10 text-left">
        <div class="font-bold">Please note:</div>
        <ul class="list-disc ml-5 mt-3">
          <template v-if="isInfluencer">
            <li>
              Flytedesk typically sends an influencer payment within two weeks
              of the ad being verified.
            </li>
            <li>
              Our billing team processes payments based on the preferences and
              information provided by the influencer in their Payment
              Authorization Form
            </li>
          </template>
          <template v-else>
            <li>
              A 20% commission has been applied to each ad on this invoice
            </li>
            <li>
              Flytedesk typically sends payment within 60 days from the last day
              of the month of this invoice
            </li>
          </template>
        </ul>
      </div>
    </div>
  </base-document>
</template>

<script>
import AddressFormat from '@/components/Core/AddressFormat';
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import ContactInfo from '@/components/Core/ContactInfo';
import BaseDocument from '@/components/Core/Documents/BaseDocument';
import InvoiceTermsQuery from '@/components/Queries/InvoiceTermsQuery';
import EditableDocumentItem from '@/components/Supplier/Billing/Document/EditableDocumentItem';
import InvoiceDocumentLineItem from '@/components/Supplier/Billing/Document/InvoiceDocument/InvoiceDocumentLineItem';
import { DocumentLineItemTypeKeys, OrderSubLineItemType } from '@/constants';
import { datetimeDb } from '@/utils/filters';

import { plus as plusIcon } from '@/vendor/icons';
import { debounce, groupBy } from 'lodash';
import moment from 'moment';
import uuid from 'uuid';

export default {
  components: {
    AddressFormat,
    BaseDocument,
    InvoiceDocumentLineItem,
    ConfirmDialog,
    InvoiceTermsQuery,
    EditableDocumentItem,
    ContactInfo
  },
  props: {
    ...BaseDocument.props,
    editable: Boolean,
    invoice: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      invoiceTermOptions: null,
      showTermsChangeDialog: false,
      termsDueDate: null,
      DocumentLineItemTypeKeys,
      isLineItemHovered: {},
      isLineItemFocused: {},

      // Icons
      plusIcon
    };
  },
  computed: {
    isDeleting() {
      return !!this.invoice.delete_started_at;
    },
    isVersion2National() {
      return this.isNational && this.invoice.version >= '2.0';
    },
    groupedLineItems() {
      let groups = Object.values(
        groupBy([...this.invoice.documentLineItems], 'orderLineItem.order.id')
      );

      groups = groups.map(lineItems => {
        let oli = lineItems[0].orderLineItem;

        return {
          title: `${oli.order.campaign.ref}, ${oli.order.ref} | ${oli.order.campaign.name}`,
          lineItems
        };
      });

      return groups;
    },
    sortedLineItems() {
      return [...this.invoice.documentLineItems].sort((a, b) => {
        return moment(a.created_at).isBefore(b.created_at) ? -1 : 1;
      });
    },
    isUnlockAllowed() {
      return (
        this.$can('pub_manage_invoices') &&
        this.invoice &&
        this.invoice.is_tracked_by_source &&
        !this.isDeleting
      );
    },
    allowEditing() {
      return (
        this.editable &&
        !this.locked &&
        !this.disabled &&
        this.$can('pub_manage_invoices')
      );
    },
    buyer() {
      return (
        this.invoice && this.invoice.customer && this.invoice.customer.buyer
      );
    },
    settings() {
      return this.invoice && this.invoice.settings;
    },
    isNational() {
      return this.settings && this.settings.isNational;
    },
    isInfluencer() {
      return this.settings?.isInfluencer;
    },
    showTotals() {
      return this.subTotal !== this.invoice.amount;
    },
    totalDiscounts() {
      let discounts = 0;

      for (let lineItem of this.invoice.documentLineItems) {
        for (let subLineItem of lineItem.children) {
          if (
            subLineItem.name !== OrderSubLineItemType.ADJUSTMENT.label &&
            subLineItem.type === OrderSubLineItemType.DISCOUNT.value
          ) {
            discounts += subLineItem.amount;
          }
        }
      }

      return discounts;
    },
    totalFees() {
      let fees = 0;

      for (let lineItem of this.invoice.documentLineItems) {
        for (let subLineItem of lineItem.children) {
          if (
            subLineItem.name !== OrderSubLineItemType.ADJUSTMENT.label &&
            subLineItem.type === OrderSubLineItemType.FEE.value
          ) {
            fees += subLineItem.amount;
          }
        }
      }

      return fees;
    },
    subTotal() {
      return this.invoice.amount - this.totalFees - this.totalDiscounts;
    },
    repEmail() {
      return this.invoice.supplier?.repUser?.email || 'ap@flytedesk.com';
    }
  },
  methods: {
    datetimeDb,
    uuid,
    setLineItemHovered(lineItem, isHovered) {
      let currentStatus = this.isLineItemHovered[lineItem.id];

      // If the status is a timeout promise, we want to cancel that promise and set a new state
      if (currentStatus && typeof currentStatus !== 'boolean') {
        clearTimeout(this.isLineItemHovered);
      }

      if (!isHovered) {
        isHovered = setTimeout(() => {
          if (this.isLineItemHovered[lineItem.id] !== true) {
            this.$set(this.isLineItemHovered, lineItem.id, false);
          }
        }, 2000);
      }

      this.$set(this.isLineItemHovered, lineItem.id, isHovered);
    },
    setLineItemFocused(lineItem, isFocused) {
      this.$set(this.isLineItemFocused, lineItem.id, isFocused);

      // Always be sure to set focus after the blur has occurred for Invoice Document state
      if (isFocused) {
        this.$nextTick(() => this.$emit('focus'));
      } else {
        this.$emit('blur');
      }
    },
    debounceChange: debounce(function(input) {
      this.$emit('change', input);
    }, 500),
    onTermsChange(terms) {
      this.$emit('change', { terms });
      this.showTermsChangeDialog = true;
      this.termsDueDate = moment(this.invoice.transaction_date).add(
        terms.days,
        'days'
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables.scss';

.invoice-document {
  position: relative;
  font-size: 14px;
  background: white;

  &.is-disabled > div {
    opacity: 0.5;
    cursor: not-allowed;
  }

  h3 {
    font-size: 16px;
    line-height: 20px;
  }

  table.doc-table /deep/ {
    border-collapse: collapse;

    & > thead,
    & > tbody {
      & > tr {
        & > td {
          border: 1px solid $color-gray;
          padding: 4px 10px;
        }
      }
    }

    & > thead {
      & > tr {
        & > td {
          background: $color-baby-blue;
          color: $color-blue;
        }
      }
    }

    .line-item-quantity {
      width: 5em;
      text-align: center;

      input {
        text-align: center;
      }
    }

    .line-item-amount {
      width: 10em;
    }
  }

  .document-corner-status {
    position: absolute;
    top: 1em;
    right: 2em;
  }

  .invoice-label-value {
    display: flex;
    flex: 0 1;
    justify-content: flex-end;

    .invoice-value {
      padding-left: 8px;
    }
  }

  .invoice-logo {
    height: 80px;

    .invoice-logo-box /deep/ {
      height: 80px !important;
      width: 300px !important;

      .image {
        justify-content: left;
      }
    }
  }

  .invoice-customer-company {
    font-weight: bold;
  }

  .invoice-primary-contact {
    margin-top: 18px;
  }

  .invoice-billing-contact {
    margin-top: 18px;
  }

  .invoice-vendor-contact {
    width: 300px;
  }

  .vendor-billing-contact {
    font-weight: bold;
    margin-top: 10px;
  }

  .invoice-header {
    margin-top: 18px;
  }

  .invoice-header-bottom {
    margin-top: 18px;
  }

  .invoice-body {
    margin-top: 40px;
  }

  .invoice-line-items-table {
    width: 100%;
  }

  .date-to {
    text-align: center;
  }

  .invoice-total-due-footer {
    font-size: 20px;
    width: 100%;
    margin-top: 20px;
    justify-content: flex-end;
    display: flex;

    .invoice-total-label {
      font-weight: bold;
      margin-right: 10px;
    }
  }

  .invoice-add-line-item {
    font-size: 1.1em;
    cursor: pointer;

    td {
      padding: 0.5em 0 !important;
      color: $color-blue !important;
      border: 1px dashed $color-blue !important;
      transition: all 0.3s;

      &:hover {
        background: $color-very-light-gray;
      }
    }

    &.is-disabled {
      td {
        color: white !important;
        border-color: white !important;
      }
    }
  }
}
</style>

<style lang="scss">
@media print {
  #app {
    .invoice-add-line-item {
      display: none !important;
    }
  }
}
</style>
